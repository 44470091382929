import Section from '../Section';
import SectionHeader from '../SectionHeader';
import iconSelectArrow from '@/assets/img/icons/ico_drop_down@2x.png';
import { breakpoint } from '@/helpers/BreakpointHelper';
import styled from 'styled-components';

export const TopHeader = styled(SectionHeader)`
  select {
    border: 0;
    border-bottom: 2px solid #2d2926;
    width: 200px;
    height: 64px;
    font-size: 32px;
    font-weight: 700;
    background: #fff url(${iconSelectArrow}) center no-repeat;
    background-size: 32px;
    background-position-y: 50%;
    background-position-x: 97%;
    ${breakpoint(640)} {
      font-size: 20px;
      width: 148px;
    }
  }
`;
export const SectionTop = styled(Section)`
  padding-bottom: 0 !important;
  ${SectionHeader} {
    padding-bottom: 64px;
  }
`;
export const FoundContent = styled.div`
  ${SectionTop} {
    padding-bottom: 40px;
  }

  ${Section} {
    &:last-child {
      padding-bottom: 0;
    }
  }
`;
