import Container from '@/components/Container';
import {
  FoundContent,
  SectionTop,
  TopHeader,
} from '@/components/Css/FoundSection';
import Section from '@/components/Section';
import Select from '@/components/Select';
import { breakpoint } from '@/helpers/BreakpointHelper';
import LayoutWithTitle from '@/layouts/LayoutWithTitle';
import { navigate, PageProps } from 'gatsby';
import React from 'react';
import styled from 'styled-components';

const PanelContainer = styled.div`
  height: 80px;
  border-bottom: 3px solid #c6c6c6;
  ${breakpoint(640)} {
    ${Container} {
      padding: 0;
    }
  }
`;
const TabNav = styled.ul`
  & > li {
    cursor: pointer;
    width: 50%;
    text-align: center;
    display: block;
    padding: 0;
    margin: 0;
  }
`;

const Tab = styled.li<{ active: boolean }>`
  cursor: pointer;
  width: 50%;
  text-align: center;
  display: block;

  .btn-tab {
    display: block;
    width: 100%;
    font-weight: 700;
    line-height: 80px;
    font-size: 20px;
    height: 80px;
    border-bottom: 3px solid #c6c6c6;
    border-color: #c6c6c6;
    color: #c6c6c6;
    transition: color 0.15s ease-in-out, border-color 0.15s ease-in-out;
    &:hover {
      border-color: #1cabe2;
      color: #1cabe2;
    }
    ${({ active }) =>
      active &&
      `
        border-color: #1cabe2;
        color: #1cabe2;
    `}
  }
`;

const TabContainer = styled.div``;
const SectionContent = styled(Section)`
  padding-top: 0 !important;
`;

interface FundUnicefKrTemplateProps extends Omit<PageProps, 'children'> {
  year: number;
}

// 기금사용처 유니세프 템플릿
const FundUnicefKrTemplate: React.FC<FundUnicefKrTemplateProps> = ({
  location,
  children,
  year,
}) => (
  <LayoutWithTitle
    location={location}
    title="기금 사용처"
    description="for every child, finances"
  >
    <PanelContainer>
      <Container>
        <TabNav className="UiTabPanel flex">
          <Tab
            active
            onClick={() => navigate(`/about-us/clear/fund/unicef-kr`)}
          >
            <span className="btn-tab">한국위원회</span>
          </Tab>
          <Tab onClick={() => navigate(`/about-us/clear/fund/unicef`)}>
            <span className="btn-tab">유니세프</span>
          </Tab>
        </TabNav>
      </Container>
    </PanelContainer>
    <TabContainer>
      <FoundContent>
        <SectionTop className="by-sub-main-layout">
          <Container>
            <TopHeader className="with-desc">
              <Select
                onChange={(e) =>
                  navigate(`/about-us/clear/fund/unicef-kr/${e.target.value}`)
                }
                value={year}
              >
                <option value="2023">2023년</option>
                <option value="2022">2022년</option>
                <option value="2021">2021년</option>
                <option value="2020">2020년</option>
                <option value="2019">2019년</option>                
              </Select>
            </TopHeader>
          </Container>
        </SectionTop>

        <SectionContent className="by-sub-main-layout">
          <Container>{children}</Container>
        </SectionContent>
      </FoundContent>
    </TabContainer>
  </LayoutWithTitle>
);

export default FundUnicefKrTemplate;
