import Button from '@/components/Button';
import {
  Render,
  Article,
  ChartContainer,
  Summary,
  TableContainer,
  NoticeBox,
} from '@/components/Css/ReportKorea';
import DonutChartComponent from '@/components/DonutChart';
import LinkSafe from '@/components/LinkSafe';
import SectionHeader from '@/components/SectionHeader';
import { Tit } from '@/components/Titles';
import React from 'react';

const incomeData = [
  {
    type: `후원금 수입`,
    data: [
      {
        name: `정기 후원`,
        amount: 115734295230,
        rate: 85.7,
        tip: `매월 정해진 금액을 후원하는 것`,
      },
      {
        name: `일시 후원`,
        amount: 5489190455,
        rate: 4,
        tip: `개인이나 단체가 비정기적으로 후원하는 것`,
      },
      {
        name: `기업 후원`,
        amount: 2843527463,
        rate: 2.1,
      },
      {
        name: `비영리기관 후원`,
        amount: 537629050,
        rate: 0.4,
      },
    ],
  },
  {
    type: `후원금 외 수입`,
    data: [
      {
        name: `본부 지원금`,
        amount: 9779249375,
        rate: 7.3,
      },
      {
        name: `이자수익 및 기타`,
        amount: 371806056,
        rate: 0.3,
        tip: `예금이자수익 등`,
      },
      {
        name: `수익사업`,
        amount: 317968637,
        rate: 0.2,
      },
    ],
  },
];
const incomeTotalArry = [0, 0];
incomeData.map((row, index) => {
  row.data.map((row2) => {
    incomeTotalArry[index] += row2.amount;
  });
});
const incomeTotal = incomeTotalArry[0] + incomeTotalArry[1];

const incomePerArry = [0, 0];
incomeData.map((row, index) => {
  row.data.map((row2) => {
    incomePerArry[index] += (row2.amount / incomeTotal) * 100;
  });
});

const spendingData = [
  {
    type: `고유목적사업`,
    data: [
      {
        name: `개발도상국 지원비`,
        amount: 105332486748,
        rate: 78,
        tip: `157개 나라의 유니세프 프로그램 지원비`,
      },
      {
        name: `아동권리 옹호 및 PR 등`,
        amount: 12561920345,
        rate: 9.3,
        tip: `후원자 관리 및 제반 홍보활동, 국내아동권리 옹호 및 교육 프로그램`,
      },
      {
        name: `인건비 및 관리 운영비`,
        amount: 6384557383,
        rate: 4.7,
        tip: `인건비 및 법인 운영관련 비용 등`,
      },
      {
        name: `본부 지원금`,
        amount: 9779249375,
        rate: 7.3,
      },
      {
        name: `기타`,
        amount: 24020323,
        rate: 0,
      },
    ],
  },
  {
    type: `기타사업`,
    data: [
      {
        name: `수익사업비용`,
        amount: 293757730,
        rate: 0.2,
      },
    ],
  },
  {
    type: `당기운영이익`,
    rowspan: true,
    data: [
      {
        name: `당기운영이익`,
        amount: 697674362,
        rate: 0.5,
      },
    ],
  },
];
const spendingTotalArry = [0, 0, 0, 0];
spendingData.map((row, index) => {
  row.data.map((row2) => {
    spendingTotalArry[index] += row2.amount;
  });
});

const spendingTotal =
  spendingTotalArry[0] +
  spendingTotalArry[1] +
  spendingTotalArry[2] +
  spendingTotalArry[3];

const numberFormat = (num: number) =>
  num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, `,`);

const ReportKorea2020: React.FC = (props) => (
  <Render>
    <ul className="btn-flex flex">
      <li>
        <LinkSafe
          to={`${process.env.BACKEND_URL}/unicef/api/common/attachFile/download?attUploadPath=L01JRy9jbGVhcg==&dispFileNm=2020_audit_report.pdf&svrFileNm=2020_audit_report.pdf`}
        >
          <Button outline full ico="down" className="btn-report">
            <span className="ico">감사보고서</span>
          </Button>
        </LinkSafe>
      </li>
      <li>
        <LinkSafe
          to={`${process.env.BACKEND_URL}/unicef/api/common/attachFile/download?attUploadPath=L01JRy9jbGVhcg==&dispFileNm=2020_report.pdf&svrFileNm=2020_report.pdf`}
        >
          <Button outline full ico="down" className="btn-report">
            <span className="ico">연차보고서</span>
          </Button>
        </LinkSafe>
      </li>
      <li>
        <LinkSafe
          to={`${process.env.BACKEND_URL}/unicef/api/common/attachFile/download?attUploadPath=L01JRy9jbGVhcg==&dispFileNm=2020_performance_report.pdf&svrFileNm=2020_performance_report.pdf`}
        >
          <Button outline full ico="down" className="btn-report">
            <span className="ico">
              기부금 모금액 및 <br />
              활용 실적
            </span>
          </Button>
        </LinkSafe>
      </li>
      <li>
        <LinkSafe
          to="https://teht.hometax.go.kr/websquare/websquare.html?w2xPath=/ui/sf/a/c/UTESFACI01.xml"
          target="_blank"
        >
          <Button outline full ico="share" className="btn-report">
            <span className="ico">국세청 공시 시스템</span>
          </Button>
        </LinkSafe>
      </li>
    </ul>

    <SectionHeader className="with-desc">
      <h2>
        <Tit size="s1">
          유니세프한국위원회는
          <br />
          후원금을 투명하게 운영합니다
        </Tit>
      </h2>
    </SectionHeader>

    <Article>
      <h3>
        <Tit size="s2">정기감사</Tit>
      </h3>
      <p className="dec">
        유니세프한국위원회는 내부 감사, 유니세프 본부 및 외부회계법인 감사를
        통해 후원금 사용의 투명성을 검증받습니다.
      </p>
      <NoticeBox>
        <ul className="blit-list">
          <li>
            <strong>유니세프한국위원회 내부 감사</strong> 연 1회 이사회 감사
          </li>
          <li>
            <strong>유니세프 본부 감사</strong> 연 1회 감사
          </li>
          <li>
            <strong>외부 감사</strong> 연 1회 (2020년 이촌회계법인 감사)
          </li>
        </ul>
      </NoticeBox>
    </Article>

    <Article>
      <h3>
        <Tit size="s2">국세청 공시</Tit>
      </h3>
      <p className="dec">
        공익법인 결산서류 공시 의무에 따라 매년 국세청에 결산 서류, 연간 기부금
        모금액 및 활용실적 등을 제출하여 국세청 웹사이트에 공시합니다.
      </p>
    </Article>

    <SectionHeader className="with-desc">
      <h2>
        <Tit size="s1">유니세프한국위원회 결산</Tit>
      </h2>
    </SectionHeader>

    <Article>
      <h3 className="sky-bd">
        <Tit size="s2" color="sky">
          2020 수입
        </Tit>
      </h3>
      <ChartContainer>
        <div className="m-hide">
          <DonutChartComponent
            centerText={`<span style='font-size: 20px;'>수입</span><br><b style='font-size: 36px;letter-spacing: -1.6px;color:#3162af'>${numberFormat(
              incomeTotal,
            )}</b><b style='font-size:20px;color:#3162af'>원</b>`}
            series={[
              {
                name: ``,
                colorByPoint: true,
                innerSize: `65%`,
                type: `pie`,
                data: [
                  {
                    name: incomeData[0].data[0].name,
                    y: incomeData[0].data[0].rate,
                  },
                  {
                    name: incomeData[0].data[1].name,
                    y: incomeData[0].data[1].rate,
                  },
                  {
                    name: incomeData[0].data[2].name,
                    y: incomeData[0].data[2].rate,
                  },
                  {
                    name: incomeData[0].data[3].name,
                    y: incomeData[0].data[3].rate,
                  },
                  {
                    name: incomeData[1].data[0].name,
                    y: incomeData[1].data[0].rate,
                  },
                  {
                    name: incomeData[1].data[1].name,
                    y: incomeData[1].data[1].rate,
                  },
                  {
                    name: incomeData[1].data[2].name,
                    y: incomeData[1].data[2].rate,
                  },
                ],
              },
            ]}
          />
        </div>
        <div className="pc-hide">
          <DonutChartComponent
            centerText={`<span style='font-size: 14px;'>수입</span><br><b style='font-size: 20px;letter-spacing: -1.6px;color:#3162af'>${numberFormat(
              incomeTotal,
            )}</b><b style='font-size:14px;color:#3162af'>원</b>`}
            series={[
              {
                name: ``,
                colorByPoint: true,
                innerSize: `65%`,
                type: `pie`,
                data: [
                  {
                    name: incomeData[0].data[0].name,
                    y: incomeData[0].data[0].rate,
                  },
                  {
                    name: incomeData[0].data[1].name,
                    y: incomeData[0].data[1].rate,
                  },
                  {
                    name: incomeData[0].data[2].name,
                    y: incomeData[0].data[2].rate,
                  },
                  {
                    name: incomeData[0].data[3].name,
                    y: incomeData[0].data[3].rate,
                  },
                  {
                    name: incomeData[1].data[0].name,
                    y: incomeData[1].data[0].rate,
                  },
                  {
                    name: incomeData[1].data[1].name,
                    y: incomeData[1].data[1].rate,
                  },
                  {
                    name: incomeData[1].data[2].name,
                    y: incomeData[1].data[2].rate,
                  },
                ],
              },
            ]}
          />
        </div>
      </ChartContainer>
      <TableContainer>
        <table className="tbl1">
          <colgroup>
            <col className="col1" />
            <col className="col2" />
            <col className="col3" />
          </colgroup>
          <thead>
            <tr>
              <th>구분</th>
              <th>금액(원)</th>
              <th>비율</th>
            </tr>
          </thead>
          {incomeData.map((row, index) => (
            <tbody key={index}>
              <tr>
                <th colSpan="3">
                  <strong>{row.type}</strong>
                </th>
              </tr>
              {row.data.map((row2) => (
                <tr>
                  <td>
                    {row2.name} {row2.tip && `*`}
                  </td>
                  <td>
                    <p className="price">{numberFormat(row2.amount)}</p>
                  </td>
                  <td>
                    <p className="price">{row2.rate.toFixed(1)}%</p>
                  </td>
                </tr>
              ))}
              <tr>
                <td>
                  <strong>소계</strong>
                </td>
                <td>
                  <p className="price">
                    {numberFormat(incomeTotalArry[index])}
                  </p>
                </td>
                <td>
                  <p className="price">{incomePerArry[index].toFixed(1)}%</p>
                </td>
              </tr>
            </tbody>
          ))}
        </table>
      </TableContainer>
      <NoticeBox>
        <ul className="blit-list">
          {incomeData.map((row, index) => (
            <>
              {row.data.map((row2, index2) => (
                <>
                  {row2.tip && (
                    <li>
                      <strong>{row2.name} : </strong>
                      {row2.tip}
                    </li>
                  )}
                </>
              ))}
            </>
          ))}
        </ul>
      </NoticeBox>
    </Article>

    <Article>
      <h3 className="sky-bd">
        <Tit size="s2" color="sky">
          2020 지출
        </Tit>
      </h3>
      <ChartContainer>
        <div className="m-hide">
          <DonutChartComponent
            centerText={`<span style='font-size: 20px;'>지출</span><br><b style='font-size: 36px;letter-spacing: -1.6px;color:#3162af'>${numberFormat(
              spendingTotal,
            )}</b><b style='font-size:20px;color:#3162af'>원</b>`}
            series={[
              {
                name: ``,
                colorByPoint: true,
                innerSize: `65%`,
                type: `pie`,
                data: [
                  {
                    name: spendingData[0].data[0].name,
                    y: spendingData[0].data[0].rate,
                  },
                  {
                    name: spendingData[0].data[1].name,
                    y: spendingData[0].data[1].rate,
                  },
                  {
                    name: spendingData[0].data[2].name,
                    y: spendingData[0].data[2].rate,
                  },
                  {
                    name: spendingData[0].data[3].name,
                    y: spendingData[0].data[3].rate,
                  },
                  {
                    name: spendingData[0].data[4].name,
                    y: spendingData[0].data[4].rate,
                  },
                  {
                    name: spendingData[1].data[0].name,
                    y: spendingData[1].data[0].rate,
                  },
                  {
                    name: spendingData[2].data[0].name,
                    y: spendingData[2].data[0].rate,
                  },
                ],
              },
            ]}
          />
        </div>
        <div className="pc-hide">
          <DonutChartComponent
            centerText={`<span style='font-size: 14px;'>지출</span><br><b style='font-size: 20px;letter-spacing: -1.6px;color:#3162af'>${numberFormat(
              spendingTotal,
            )}</b><b style='font-size:14px;color:#3162af'>원</b>`}
            series={[
              {
                name: ``,
                colorByPoint: true,
                innerSize: `65%`,
                type: `pie`,
                data: [
                  {
                    name: spendingData[0].data[0].name,
                    y: spendingData[0].data[0].rate,
                  },
                  {
                    name: spendingData[0].data[1].name,
                    y: spendingData[0].data[1].rate,
                  },
                  {
                    name: spendingData[0].data[2].name,
                    y: spendingData[0].data[2].rate,
                  },
                  {
                    name: spendingData[0].data[3].name,
                    y: spendingData[0].data[3].rate,
                  },
                  {
                    name: spendingData[0].data[4].name,
                    y: spendingData[0].data[4].rate,
                  },
                  {
                    name: spendingData[1].data[0].name,
                    y: spendingData[1].data[0].rate,
                  },
                  {
                    name: spendingData[2].data[0].name,
                    y: spendingData[2].data[0].rate,
                  },
                ],
              },
            ]}
          />
        </div>
      </ChartContainer>

      <TableContainer>
        <table className="tbl1">
          <colgroup>
            <col className="col1" />
            <col className="col2" />
            <col className="col3" />
          </colgroup>
          <thead>
            <tr>
              <th>구분</th>
              <th>금액(원)</th>
              <th>비율</th>
            </tr>
          </thead>
          {spendingData.map((row, index) => (
            <tbody key={index}>
              {!row.rowspan ? (
                <tr>
                  <th colSpan="3">
                    <strong>{row.type}</strong>
                  </th>
                </tr>
              ) : (
                <tr>
                  <th>
                    <strong>{row.type}</strong>
                  </th>
                  <th>
                    <p className="price">{numberFormat(row.data[0].amount)}</p>
                  </th>
                  <th>
                    <p className="price">
                      {((row.data[0].amount / spendingTotal) * 100).toFixed(1)}%
                    </p>
                  </th>
                </tr>
              )}
              {!row.rowspan &&
                row.data.map((row2) => (
                  <tr>
                    <td>
                      {row2.name} {row2.tip && `*`}
                    </td>
                    <td>
                      <p className="price">{numberFormat(row2.amount)}</p>
                    </td>
                    <td>
                      <p className="price">{row2.rate.toFixed(1)}%</p>
                    </td>
                  </tr>
                ))}
            </tbody>
          ))}
        </table>
      </TableContainer>
      <NoticeBox>
        <ul className="blit-list">
          {spendingData.map((row, index) => (
            <>
              {row.data &&
                row.data.map((row2, index2) => (
                  <>
                    {row2.tip && (
                      <li>
                        <strong>{row2.name} : </strong>
                        {row2.tip}
                      </li>
                    )}
                  </>
                ))}
            </>
          ))}
        </ul>
      </NoticeBox>
    </Article>

    <Article>
      <h3 className="sky-bd">
        <Tit size="s2" color="sky">
          {props.selectYear} 2020 개발도상국 지원비
        </Tit>
      </h3>
      <TableContainer>
        <Summary>개발도상국 어린이 지원 총계 : 105,332,486,748원</Summary>
        <table className="tbl3">
          <colgroup>
            <col className="col1" />
            <col className="col2" />
            <col className="col4" />
          </colgroup>
          <thead>
            <tr>
              <th>구분</th>
              <th>금액(원)</th>
              <th>비율</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>
                <strong>일반사업비</strong>
              </th>
              <th>
                <p className="price">92,807,013,913</p>
              </th>
              <th>
                <p className="price">88%</p>
              </th>
            </tr>
            <tr>
              <th>
                <strong>지정사업비</strong>
              </th>
              <th>
                <p className="price">12,525,472,835</p>
              </th>
              <th>
                <p className="price">12%</p>
              </th>
            </tr>
            <tr>
              <th>
                <strong>아시아 교육 사업</strong>
              </th>
              <th>
                <p className="price">3,933,784,561</p>
              </th>
              <th />
            </tr>
            <tr>
              <th colSpan="3">
                <strong>글로벌 캠페인</strong>
              </th>
            </tr>
            <tr>
              <td>
                <p className="country">에이즈 예방</p>
              </td>
              <td>
                <p className="price">6,015,603,928</p>
              </td>
              <td />
            </tr>
            <tr>
              <td>
                <p className="country">어린이 보호</p>
              </td>
              <td>
                <p className="price">700,000,000</p>
              </td>
              <td />
            </tr>
            <tr>
              <td>
                <p className="country">교육</p>
              </td>
              <td>
                <p className="price">200,000,000</p>
              </td>
              <td />
            </tr>
            <tr>
              <td>
                <p className="country">보건</p>
              </td>
              <td>
                <p className="price">90,492,598</p>
              </td>
              <td />
            </tr>
            <tr>
              <td>
                <p className="country">식수와 위생</p>
              </td>
              <td>
                <p className="price">87,982,842</p>
              </td>
              <td />
            </tr>
            <tr>
              <th colSpan="3">
                <strong>국가별 지정사업</strong>
              </th>
            </tr>
            <tr>
              <td colSpan="3">
                <strong>아시아</strong>
              </td>
            </tr>
            <tr>
              <td>
                <p className="country">베트남(교육)</p>
              </td>
              <td>
                <p className="price">456,702,700</p>
              </td>
              <td />
            </tr>
            <tr>
              <td>
                <p className="country">라오스(교육)</p>
              </td>
              <td>
                <p className="price">200,000,000</p>
              </td>
              <td />
            </tr>
            <tr>
              <td>
                <p className="country">몽골(안전하고 깨끗한 환경)</p>
              </td>
              <td>
                <p className="price">200,000,000</p>
              </td>
              <td />
            </tr>
            <tr>
              <td>
                <p className="country">미얀마(교육)</p>
              </td>
              <td>
                <p className="price">20,000,000</p>
              </td>
              <td />
            </tr>
            <tr>
              <td colSpan="3">
                <strong>아프리카</strong>
              </td>
            </tr>
            <tr>
              <td>
                <p className="country">말라위(영양)</p>
              </td>
              <td>
                <p className="price">40,000,000 </p>
              </td>
              <td />
            </tr>
            <tr>
              <th colSpan="3">
                <strong>긴급구호</strong>
              </th>
            </tr>
            <tr>
              <td>
                <p className="country">코로나19</p>
              </td>
              <td>
                <p className="price">485,814,623</p>
              </td>
              <td />
            </tr>
            <tr>
              <td>
                <p className="country">레바논</p>
              </td>
              <td>
                <p className="price">95,091,583</p>
              </td>
              <td />
            </tr>
          </tbody>
        </table>
      </TableContainer>
      <NoticeBox>
        <ul className="blit-list">
          <li>
            <strong>일반사업비 : </strong>전 세계 157개 개발도상국의 유니세프
            사업비(보건, 에이즈, 식수와 위생, 영양, 교육, 어린이 보호, 사회통합
            등)
          </li>
          <li>
            <strong>지정사업비 : </strong>한국위원회가 직접 지원대상국과 사업을
            지정해서 보내는 사업비
          </li>
        </ul>
      </NoticeBox>
      <p
        className="tip"
        css={`
          margin-top: 32px;
        `}
      >
        ※ 사단법인 유니세프한국위원회는 정기적인 외부회계법인의 회계감사를 통해,
        법인의 공익성과 운영 투명성을 추구하고 있습니다. <br />
        {` `}
        2020
        {` `}
        회계연도 재무제표 등 경영성과 또한 이촌회계법인의 실사 결과 적정하게
        표시되어 있음을 인정 받았습니다.
      </p>
    </Article>
  </Render>
);
export default ReportKorea2020;
